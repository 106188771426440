import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { Dashboard } from "./components/dashboard";
import { NotFound } from "./components/not-found";
import { NewMomentaPatientForm } from "./components/new-momenta-patient";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="prescriptionPayments"
        element={<Dashboard paymentsView={true} />}
      />
      <Route path="/newMomentaPatient" element={<NewMomentaPatientForm />} />
      <Route element={<App />} />
    </Routes>
  </BrowserRouter>
);
