import Close from "../images/incorrect.svg";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import logger from "../helpers/logger";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { apiPaymentsEndpoint } from "./manage-patients";
import { apiEndpoint } from "./dashboard";
import { useCallback, useEffect, useState } from "react";

const cancellationReasons = {
  unknown: {
    value: "unknown",
    description: "unknown",
  },
  moreSupport: {
    value: "moreSupport",
    description: "Needs more support",
  },
  doNotLikeMeals: {
    value: "doNotLikeMeals",
    description: "Doesn't like the meals",
  },
  programmeTooHard: {
    value: "programmeTooHard",
    description: "The programme is too hard",
  },
  strugglingWithApp: {
    value: "strugglingWithApp",
    description: "Struggling to use the app",
  },
  takeBreak: {
    value: "takeBreak",
    description: "Need to take a break",
  },
  other: {
    value: "other",
    description: "Something else...",
  },
};

const getReasons = () => {
  const options = [];

  for (const [key, value] of Object.entries(cancellationReasons)) {
    console.log(`${key}: ${value}`);
    options.push(
      <option key={`reason-${value}`} value={value.value}>
        {value.description}
      </option>
    );
  }

  return options;
};

export const CancellationModal = ({
  data,
  refresh,
  dismissModal,
}: {
  data: { [key: string]: any } | null;
  refresh: () => void;
  dismissModal: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [otherCancellationReason, setOtherCancellationReason] = useState<
    string | null
  >(null);
  const [cancellationReason, setCancellationReason] = useState("unknown");

  const handleCancelUserSubscription = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${data?.email}/churned`,
          data: {
            cancellationReason,
            otherCancellationReason,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
          alert("Cancelled user");

        logger.info(
          "Marked Pending cancellation:",
          response1
        );
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: "Subscription was cancelled",
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#e3e6df99",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          minHeight: "60%",
          backgroundColor: "white",
          margin: "auto",
          marginTop: "5%",
          borderRadius: 8,
          padding: 24,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 12,
          }}
        >
          <img
            src={Close}
            alt=""
            onClick={() => {
              dismissModal();
            }}
            style={{ maxHeight: 48, maxWidth: 48 }}
          />
        </div>
        <div>
          <h1>Cancel this patient?</h1>
          <p>
            This will cancel any subscriptions in our database and mark the patient as
            pending cancellation. They will be marked as a churn in CIO straight
            away and once 4 weeks have passed since their last digital patient
            they will also be marked as a churn in the app database so their
            content will no longer unlock.
          </p>
          <p>Cancellation reason</p>
          <select
            onChange={(e) => {
              setCancellationReason(e.target.value);
              if (e.target.value !== cancellationReasons["other"].value) {
                setOtherCancellationReason(null);
              }
            }}
            defaultValue={cancellationReason}
          >
            {getReasons()}
          </select>
          {cancellationReason === cancellationReasons["other"].value && (
            <>
              <p>Other input box reason</p>
              <textarea
                onChange={(e) => {
                  setOtherCancellationReason(e.target.value);
                }}
                draggable={false}
                style={{ minHeight: 64, width: "100%" }}
              />
            </>
          )}
          <div
            style={{
              alignContent: "center",
              textAlign: "center",
              marginTop: 24,
            }}
          >
            <p>Are you sure you want to continue?</p>
            <button
              className="cta cancelUserButton"
              onClick={() => {
                handleCancelUserSubscription();
              }}
            >
              {"Cancel User Subscription"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
