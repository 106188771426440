import Close from "../../images/incorrect.svg"
import axios from "axios"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../helpers/firebaseHelper"
import logger from "../../helpers/logger"
import "./../globals.css"
import { updatePatientNotes } from "../../helpers/updatePatientNotes"
import promiseAccumulator from "../../helpers/promiseAccumulator"
import { apiEndpoint } from "./../dashboard"
import { useCallback, useEffect, useState } from "react"
import {
  mounjaroOptions,
  ozempicOptions,
  rybelsusOptions,
  wegovyOptions,
} from "./rowBottomContainer"
import { glpApiEndpoint } from "../prescription-payments"

const getMedications = () => {
  return ozempicOptions
    .concat(rybelsusOptions)
    .concat(wegovyOptions)
    .concat(mounjaroOptions)
}

export const pharmacyDictionary: {
  [key: number]: { value: number; label: string }
} = {
  1: {
    value: 1,
    label: "The Independent Pharmacy",
  },
  0: {
    value: 0,
    label: "Phlo",
  },
  2: {
    value: 2,
    label: "Chemist4U",
  },
}

const getPharmacies = () => {
  return Object.values(pharmacyDictionary).map(
    (pharmacy: { value: number; label: string }) => {
      return (
        <option key={pharmacy.label} value={pharmacy.value}>
          {pharmacy.label}
        </option>
      )
    }
  )
}

export const CreateOrderModal = ({
  data,
  refresh,
  dismissModal,
}: {
  data: { [key: string]: any } | null
  refresh: () => void
  dismissModal: () => void
}) => {
  const [user] = useAuthState(auth)
  const [authToken, setAuthToken] = useState<string | undefined>()
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken()
    setAuthToken(token)
  }, [user])
  const [medication, setMedication] = useState<string>(
    data?.medication || null
  )
  const [pharmacy, setPharmacy] = useState<string>(data?.pharmacyId || 1)
  const [sendNeedles, setSendNeedles] = useState<boolean>(
    data?.sendNeedles || null
  )
  const [sendSharpsBox, setSendSharpsBox] = useState<boolean>(
    data?.sendSharpsBox || null
  )

  useEffect(() => {
    getAuthToken()
  }, [getAuthToken])

  const handleCreateOrder = () => {
    const quantity = data?.medicationReview?.preapprovedQuantity && data.medicationReview.preapprovedQuantity > 1 ? data.medicationReview.preapprovedQuantity : undefined
    const confirm = window.confirm(
      quantity ? `Are you sure you want to submit a consultation for ${quantity} pens for this patient? Make sure they have paid for this number as we have no backend protection beyond this point` : "Are you sure you want to submit a consultation for this patient?"
    )
    console.log("data at this point is", data)

    const orderToSend: any = {
      medicationReview: data?.initialOrder
        ? undefined
        : {
          ...data?.medicationReview,
          newDosage: medication,
          MedicationReviewAnswers:
            data?.medicationReview?.medicationReviewAnswers,
        },
      initialConsultation: data?.initialOrder
        ? {
          ...data?.medicationReview,
          newDosage: medication,
          answers: data?.medicationReview?.answers,
        }
        : undefined,
      prescriptionPayment: {
        id: data?.prescriptionPayment.paymentId,
        customerId: data?.prescriptionPayment.customerId,
        chargeId: data?.prescriptionPayment.chargeId,
      },
      email: data?.email,
      initialOrder: data?.initialOrder,
      heyCeres: true,
      gp: data?.gpConsent,
      sendNeedles,
      sendSharpsBox,
    }

    if (data?.initialOrder) {
      orderToSend.tipFbsUrl = data?.tipFbsUrl
      orderToSend.tipPersonaSelfieUrl = data?.tipPersonaSelfieUrl
    }

    const requests = [
      () =>
        axios({
          method: "post",
          url: `${glpApiEndpoint}admin/v1/pharmacy/${pharmacy}/${data?.email}/order`,
          headers: {
            "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
            "ignoreratelimit": quantity > 1 ? 'true' : 'false',
          },
          data: orderToSend,
        }),
      // }).catch((err) => {
      //   alert(JSON.stringify(err.response.data));
      //   throw err;
      // }),
    ]
    if (quantity && quantity > 1) {
      for (let i = 1; i < quantity; i++) {
        requests.push(() =>
          axios({
            method: "post",
            url: `${glpApiEndpoint}admin/v1/pharmacy/${pharmacy}/${data?.email}/order`,
            headers: {
              "x-api-key": `${process.env.REACT_APP_GLP_API_KEY}`,
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
              "ignoreratelimit": 'true',
            },
            data: orderToSend,
          }))
      }
    }
    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          if (response1.data === "Rate limited") {
            alert("Rate limited - try again in 10 minutes")
          } else if (response1?.statusText === "OK") {
            const orderId = response1?.data?.data?.id
            alert(quantity > 1 ? `${quantity} x Prescription orders were placed` : "Prescription order was placed")
            updatePatientNotes({
              user,
              authToken,
              data: { email: data?.email },
              note: `Prescription order (ID: ${orderId}) for ${requests.length} pen(s) was manually placed.`,
            })
          }
          return [response1]
        })
        .then(() => {
          refresh()
        })
        .catch((error) => {
          console.log(
            `Error placing a prescription order for ${data?.email}.`,
            error
          )
          alert(error)
        })
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#e3e6df99",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          minHeight: "60%",
          backgroundColor: "white",
          margin: "auto",
          marginTop: "5%",
          borderRadius: 8,
          padding: 24,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 12,
          }}
        >
          <img
            src={Close}
            alt=""
            onClick={() => {
              dismissModal()
            }}
            style={{ maxHeight: 48, maxWidth: 48 }}
          />
        </div>
        <div>
          <h1>Create a new prescription</h1>
          <p>
            This will create a new order with the pharmacy linked to this
            payment and including this medication review response. It will not
            trigger any payment to be taken.
          </p>
          <h4>Medication to order</h4>
          <select
            onChange={(e) => {
              setMedication(e.target.value)
            }}
            defaultValue={medication}
          >
            {getMedications()}
          </select>
          <h4>Pharmacy</h4>
          <select
            onChange={(e) => {
              setPharmacy(e.target.value)
            }}
            defaultValue={pharmacy}
          >
            {getPharmacies()}
          </select>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
            <h5 style={{marginTop: 5, marginBottom: 5}}>Add needles</h5>
            <input
              type="checkbox"
              checked={sendNeedles}
              onChange={() => setSendNeedles(!sendNeedles)}
            />

          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>

            <h5 style={{marginTop: 5, marginBottom: 5}}>Add sharps box</h5>
            <input
              type="checkbox"
              checked={sendSharpsBox}
              onChange={() => setSendSharpsBox(!sendSharpsBox)}
            />
          </div>

          <div
            style={{
              alignContent: "center",
              textAlign: "center",
              marginTop: 24,
            }}
          >
            <p>Are you sure you want to place this order?</p>
            <button
              className="cta cancelUserButton"
              onClick={() => {
                handleCreateOrder()
              }}
            >
              {"Send to pharmacy"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
