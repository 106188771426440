export default {
  root: {},
  title: {
    marginTop: "0",
  },
  list: {
    root: {},
    listItem: {},
  },
  listItem: {
    root: {},
    content: {},
  },
};
